package de.ohhhmhhh.frontend.admin.helper

import androidx.compose.runtime.Composable
import androidx.compose.runtime.produceState

@Composable
fun <T> rememberSuspendable(key: Any? = Unit, block: suspend () -> T) = rememberSuspendable(null, key, block)

@Composable
fun <T> rememberSuspendable(
    initial: T,
    key: Any? = Unit,
    block: suspend () -> T
) = produceState(initialValue = initial, key) {
    try {
        value = block()
    } catch (e: Throwable) {
        console.log(e)
    }
}