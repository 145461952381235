package de.ohhhmhhh.backend.sdk.user

import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.shared.PageResultEntity
import de.ohhhmhhh.backend.models.model.user.ActivityEntity
import de.ohhhmhhh.backend.models.model.user.CreateUserRequest
import de.ohhhmhhh.backend.models.model.user.UserEntity
import de.ohhhmhhh.backend.models.model.user.UserFilterEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*

class UserServiceImpl(private val client: HttpClient) : UserService {
    override suspend fun get(id: String): UserEntity {
        return client.get("/users/$id").body()
    }

    override suspend fun getActivities(id: String): List<ActivityEntity> {
        return client.get("/users/$id/activities").body()
    }

    override suspend fun getAll(request: PageRequestEntity<UserFilterEntity>): PageResultEntity<UserEntity> {
        return client.post("/users") {
            setBody(request)
        }.body()
    }

    override suspend fun getCurrent(): UserEntity? {
        val response = client.get("/users/current")
        return if (response.status == HttpStatusCode.OK) response.body() else null
    }

    override suspend fun getEditors(): List<UserEntity> {
        return client.get("/users/editors").body()
    }

    override suspend fun create(request: CreateUserRequest): String {
        return client.post("/users/create") {
            setBody(request)
        }.body()
    }

    override suspend fun set(user: UserEntity) {
        client.put("/users/${user.id}") {
            setBody(user)
        }
    }

    override suspend fun resetPassword(id: String) {
        client.get("/users/$id/reset-password")
    }

    override suspend fun delete(id: String) {
        client.delete("/users/$id")
    }
}