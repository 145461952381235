package de.ohhhmhhh.frontend.admin.helper

import androidx.compose.runtime.getValue
import de.ohhhmhhh.backend.models.model.user.UserRoleEntity
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen
import de.ohhhmhhh.frontend.admin.navigation.composable
import de.ohhhmhhh.frontend.admin.ui.components.other.SideNavigation
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.renderComposable

fun renderUI() {

    renderComposable(rootElementId = "root") {
        val navigator = inject<Navigator>()
        val destination by navigator.state

        Div({
            classes("container-fluid")
        }) {
            Div({
                classes("row")
            }) {
                Div({
                    classes("col-2")
                }) {
                    val user by rememberSuspendable { get<CurrentUserProvider>().get() }
                    if (user != null) {
                        val items = if (user?.role?.hasRole(UserRoleEntity.ADMIN) == true) {
                            listOf(
                                "Nutzer" to Screen.UserList,
                                "Gutscheine" to Screen.VoucherList,
                                "Artikel" to Screen.ArticleList,
                                "Kategorien" to Screen.CategoryEdit,
                                "Kommentare" to Screen.CommentList,
                                "Medien" to Screen.AssetList,
                                "Ausgaben" to Screen.EpisodeList,
                                "Rechnungen" to Screen.InvoiceList,
                                "Texte" to Screen.TextList,
                                "Workout Pläne" to Screen.WorkoutList,
                                "Startseite" to Screen.LandingEdit,
                                "Teamseite" to Screen.TeamEdit,
                                "Berichte" to Screen.ReportList,
                                "Ausloggen" to Screen.External(
                                    "${Constants.FRONTEND_URL}/authentication/logout"
                                )
                            )
                        } else {
                            listOf(
                                "Artikel" to Screen.ArticleList,
                                "Ausloggen" to Screen.External(
                                    "${Constants.FRONTEND_URL}/authentication/logout"
                                )
                            )
                        }

                        SideNavigation(
                            firstName = user!!.firstName,
                            lastName = user!!.lastName,
                            email = user!!.emailAddress,
                            items = items,
                            onSelect = navigator::navigate
                        )
                    }
                }
                Div({
                    classes("col-10", "content-container")
                    style {
                        backgroundColor(Color.white)
                        display(DisplayStyle.Flex)
                        flexDirection(FlexDirection.Column)
                    }
                }) {
                    destination.composable(navigator)
                }
            }
        }
    }
}