package de.ohhhmhhh.frontend.admin.helper

/**
 * This function wraps the existing fetch function (used for http requests from JS). Purpose is to set the credentials
 * property to 'include' which leads to including cookies in general and is needed to enable cookie-based
 * authentication. This is necessary because Ktor provides no way to set it from Kotlin side.
 */
fun decorateFetch() {
    js(
        """
        window.originalFetch = window.fetch;
        window.fetch = function (resource, init) {
            init = Object.assign({}, init);
            init.credentials = init.credentials !== undefined ? init.credentials : 'include';
            return window.originalFetch(resource, init);
        };
    """
    )
}