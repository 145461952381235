package de.ohhhmhhh.frontend.admin.ui.screens.article.edit

import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.models.model.article.ArticleEntity
import de.ohhhmhhh.backend.models.model.article.UpsertArticleRequest
import de.ohhhmhhh.backend.models.model.user.UserEntity
import de.ohhhmhhh.backend.sdk.article.ArticleService
import de.ohhhmhhh.backend.sdk.category.CategoryService
import de.ohhhmhhh.backend.sdk.user.UserService
import de.ohhhmhhh.frontend.admin.helper.CurrentUserProvider
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import de.ohhhmhhh.frontend.admin.ui.components.inputs.SelectItem
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.datetime.*

class ArticleEditViewModel(
    private val articleService: ArticleService,
    private val categoryService: CategoryService,
    private val userService: UserService,
    private val currentUserProvider: CurrentUserProvider
) : BaseViewModel<ArticleEditState>(ArticleEditState()) {
    private companion object {
        const val KEY_CURRENT = "CURRENT_REVISION"
    }

    private val revisions = mutableMapOf<String, ArticleEntity>()

    init {
        launch {
            val editors = userService.getEditors()
            val items = editors.map {
                SelectItem(it.id, "${it.firstName} ${it.lastName}".ifBlank { it.displayName ?: "" })
            }.sortedBy { it.title }
            update { copy(authors = items) }
        }

        launch {
            val categories = categoryService.getAll().sorted()
            update { copy(categories = categories) }
        }
    }

    fun load(id: String?) {
        if (id == null) {
            update { copy(locked = true) }
            return
        }
        launch {
            val userId = currentUserProvider.get()?.id ?: return@launch
            val editorId = articleService.getEditor(id)
            if (editorId == null || userId == editorId) {
                articleService.setEditor(id, userId)
                update { copy(locked = true) }
                loadInternal(id)
            } else {
                val editor = userService.get(editorId).derivedName()
                update { copy(id = id, editor = editor) }
            }
        }
    }

    @OptIn(DelicateCoroutinesApi::class)
    fun save() = GlobalScope.launch {
        submit()

        val state = value()
        val request = UpsertArticleRequest(
            length = state.length,
            name = state.name.ifBlank { null },
            title = state.title,
            content = state.content.compress(),
            summary = state.summary,
            authorId = state.authorId,
            category = state.category,
            headerUrl = state.imageUrl,
            isVideo = state.isVideo,
            isAudio = state.isAudio,
            isLive = state.isLive,
            isPublished = state.isPublished,
            publishDate = state.publishDate.toInstant(TimeZone.currentSystemDefault()),
        )

        val id = if (state.new) {
            articleService.create(request).also { articleId ->
                currentUserProvider.get()?.id?.let { userId ->
                    articleService.setEditor(articleId, userId)
                }
            }
        } else {
            articleService.update(state.id, request)
            state.id
        }

        update { copy(saved = uuid4().toString()) }

        loadInternal(id)
    }

    fun lock() {
        val id = value().id.ifBlank { null } ?: return
        launch {
            val userId = currentUserProvider.get()?.id ?: return@launch
            articleService.setEditor(id, userId)
            update { copy(locked = true) }
            loadInternal(id)
        }
    }

    @OptIn(DelicateCoroutinesApi::class)
    fun unlock() {
        val id = value().id.ifBlank { null } ?: return
        GlobalScope.launch { articleService.setEditor(id, null) }
    }

    fun delete() = launch {
        articleService.delete(value().id)
    }

    fun setPublished(published: Boolean) = update { copy(isPublished = published) }
    fun setPublishDate(publishDate: LocalDateTime) = update { copy(publishDate = publishDate) }
    fun setName(name: String) = update { copy(name = name) }
    fun setTitle(title: String) = update { copy(title = title) }
    fun setSummary(summary: String) = update { copy(summary = summary) }
    fun setLength(length: Int) = update { copy(length = length) }
    fun setCategory(category: String) = update { copy(category = category) }
    fun setContent(content: String) = update { copy(content = content) }
    fun setAuthor(authorId: String) = update { copy(authorId = authorId) }
    fun setVideo(video: Boolean) = update { copy(isVideo = video) }
    fun setAudio(audio: Boolean) = update { copy(isAudio = audio) }
    fun setLive(live: Boolean) = update { copy(isLive = live) }
    fun setImageUrl(imageUrl: String) = update { copy(imageUrl = imageUrl) }

    fun setRevision(revision: String, persist: Boolean = true) {
        val state = value()

        // Copy state to the current revision if existing
        if (persist) {
            revisions[state.revision]?.run {
                revisions[state.revision] = copy(
                    length = state.length,
                    name = state.name.ifBlank { null },
                    title = state.title,
                    content = state.content,
                    summary = state.summary,
                    authorId = state.authorId,
                    category = state.category,
                    headerUrl = state.imageUrl,
                    isVideo = state.isVideo,
                    isAudio = state.isAudio,
                    isLive = state.isLive,
                    isPublished = state.isPublished,
                    publishDate = state.publishDate.toInstant(TimeZone.currentSystemDefault()),
                )
            }
        }


        revisions[revision]?.also {
            update {
                copy(
                    id = it.id,
                    new = false,
                    name = it.name ?: "",
                    isPublished = it.isPublished,
                    title = it.title,
                    summary = it.summary,
                    category = it.category,
                    authorId = it.authorId,
                    length = it.length,
                    isVideo = it.isVideo,
                    isAudio = it.isAudio,
                    isLive = it.isLive,
                    content = it.content,
                    publishDate = it.publishDate.toLocalDateTime(TimeZone.currentSystemDefault()),
                    imageUrl = it.headerUrl,
                    revision = revision,
                    savable = revision == KEY_CURRENT
                )
            }
        }
    }

    private suspend fun loadInternal(id: String) {
        val set = articleService.getRevisions(id)
        val newest = set.revisions.maxBy { it.created }
        val revisions = set.revisions.groupBy { it.created.toString() }.mapValues { it.value.first() } +
                mapOf(KEY_CURRENT to newest)
        this.revisions.clear()
        this.revisions.putAll(revisions)

        val entries = set.revisions.sortedBy { it.created }.map { article ->
            val creator = set.creators.first { it.id == article.creatorId }.derivedName()
            val offset = TimeZone.currentSystemDefault().offsetAt(article.created)
            val date = article.created.format(Formats.Instant.dateTime, offset)
            val key = article.created.toString()
            val label = "$date - $creator"
            SelectItem(key, label)
        } + SelectItem(KEY_CURRENT, "Aktuell")
        update { copy(revisions = entries) }

        setRevision(KEY_CURRENT, false)
    }

    private fun UserEntity.derivedName(): String {
        return ("$firstName $lastName").trim().ifEmpty { displayName ?: "" }
    }
}

fun String.prettify(): String {
    return try {
        val json = JSON.parse<dynamic>(this)
        JSON.stringify(json, null, 2)
    } catch (e: Throwable) {
        this
    }
}

fun String.compress(): String {
    return try {
        val json = JSON.parse<dynamic>(this)
        return JSON.stringify(json)
    } catch (e: Throwable) {
        this
    }
}