package de.ohhhmhhh.frontend.admin.mvvm

import androidx.compose.runtime.mutableStateOf
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.launch
import kotlin.coroutines.EmptyCoroutineContext

open class BaseViewModel<State>(initial: State) {
    private val scope = CoroutineScope(EmptyCoroutineContext + SupervisorJob())

    private var virtual = initial
    var state = mutableStateOf(initial)
        private set


    fun value() = state.value

    fun update(block: State.() -> State) {
        state.value = state.value.block()
        virtual = virtual.block()
    }

    fun memorize(block: State.() -> State) {
        virtual = virtual.block()
    }

    fun submit() {
        update { virtual }
    }

    fun launch(block: suspend () -> Unit) {
        scope.launch { block() }
    }

    fun launchBackground(block: suspend () -> Unit) {
        GlobalScope.launch { block() }
    }
}