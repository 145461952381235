package de.ohhhmhhh.frontend.admin.helper

import de.ohhhmhhh.backend.models.model.user.UserRoleEntity
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch

fun checkAuthentication() {
    MainScope().launch {
        val service by injectLazy<CurrentUserProvider>()
        val user = service.get()
        if (user == null || !user.role.hasRole(UserRoleEntity.EDITOR)) {
            window.location.href = "${Constants.FRONTEND_URL}/authentication/login"
        }
    }
}