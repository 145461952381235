package de.ohhhmhhh.backend.sdk

import de.ohhhmhhh.backend.sdk.article.ArticleService
import de.ohhhmhhh.backend.sdk.article.ArticleServiceImpl
import de.ohhhmhhh.backend.sdk.asset.AssetService
import de.ohhhmhhh.backend.sdk.asset.AssetServiceImpl
import de.ohhhmhhh.backend.sdk.category.CategoryService
import de.ohhhmhhh.backend.sdk.category.CategoryServiceImpl
import de.ohhhmhhh.backend.sdk.checkout.CheckoutService
import de.ohhhmhhh.backend.sdk.checkout.CheckoutServiceImpl
import de.ohhhmhhh.backend.sdk.comment.CommentService
import de.ohhhmhhh.backend.sdk.comment.CommentServiceImpl
import de.ohhhmhhh.backend.sdk.episode.EpisodeService
import de.ohhhmhhh.backend.sdk.episode.EpisodeServiceImpl
import de.ohhhmhhh.backend.sdk.invoice.InvoiceService
import de.ohhhmhhh.backend.sdk.invoice.InvoiceServiceImpl
import de.ohhhmhhh.backend.sdk.landing.LandingPageService
import de.ohhhmhhh.backend.sdk.landing.LandingPageServiceImpl
import de.ohhhmhhh.backend.sdk.report.ReportService
import de.ohhhmhhh.backend.sdk.report.ReportServiceImpl
import de.ohhhmhhh.backend.sdk.subscriptions.SubscriptionService
import de.ohhhmhhh.backend.sdk.subscriptions.SubscriptionServiceImpl
import de.ohhhmhhh.backend.sdk.team.TeamService
import de.ohhhmhhh.backend.sdk.team.TeamServiceImpl
import de.ohhhmhhh.backend.sdk.text.TextService
import de.ohhhmhhh.backend.sdk.text.TextServiceImpl
import de.ohhhmhhh.backend.sdk.user.UserService
import de.ohhhmhhh.backend.sdk.user.UserServiceImpl
import de.ohhhmhhh.backend.sdk.voucher.VoucherService
import de.ohhhmhhh.backend.sdk.voucher.VoucherServiceImpl
import de.ohhhmhhh.backend.sdk.workout.WorkoutService
import de.ohhhmhhh.backend.sdk.workout.WorkoutServiceImpl
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.json.Json

@OptIn(ExperimentalSerializationApi::class)
class OMServices(backendUrl: String) {
    private val client by lazy {
        HttpClient(ENGINE) {
            install(ContentNegotiation) {
                json(Json {
                    explicitNulls = false
                    ignoreUnknownKeys = true
                })
            }
            install(Logging) {
                level = LogLevel.NONE
            }
            defaultRequest {
                url(backendUrl)
                contentType(ContentType.Application.Json)
            }
        }
    }

    val articles: ArticleService by lazy { ArticleServiceImpl(client) }
    val users: UserService by lazy { UserServiceImpl(client) }
    val assets: AssetService by lazy { AssetServiceImpl(client) }
    val episodes: EpisodeService by lazy { EpisodeServiceImpl(client) }
    val landingPage: LandingPageService by lazy { LandingPageServiceImpl(client) }
    val workouts: WorkoutService by lazy { WorkoutServiceImpl(client) }
    val categories: CategoryService by lazy { CategoryServiceImpl(client) }
    val comments: CommentService by lazy { CommentServiceImpl(client) }
    val invoices: InvoiceService by lazy { InvoiceServiceImpl(client) }
    val teams: TeamService by lazy { TeamServiceImpl(client) }
    val texts: TextService by lazy { TextServiceImpl(client) }
    val vouchers: VoucherService by lazy { VoucherServiceImpl(client) }
    val subscriptions: SubscriptionService by lazy { SubscriptionServiceImpl(client) }
    val checkouts: CheckoutService by lazy { CheckoutServiceImpl(client) }
    val reports: ReportService by lazy { ReportServiceImpl(client) }
}