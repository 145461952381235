package de.ohhhmhhh.backend.sdk.subscriptions

import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.shared.PageResultEntity
import de.ohhhmhhh.backend.models.model.subscriptions.CreateSubscriptionRequest
import de.ohhhmhhh.backend.models.model.subscriptions.ImportSubscriptionRequest
import de.ohhhmhhh.backend.models.model.subscriptions.SubscriptionEntity
import de.ohhhmhhh.backend.models.model.subscriptions.SubscriptionFilterEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.datetime.LocalDate

class SubscriptionServiceImpl(
    private val client: HttpClient
) : SubscriptionService {
    override suspend fun getNextCharge(id: String): LocalDate? {
        val response = client.get("/subscriptions/$id/nextcharge")
        val length = response.contentLength()
        return if (length == null || length == 0L) {
            null
        } else {
            response.body()
        }
    }

    override suspend fun getAll(request: PageRequestEntity<SubscriptionFilterEntity>): PageResultEntity<SubscriptionEntity> {
        return client.post("/subscriptions") {
            setBody(request)
        }.body()
    }

    override suspend fun create(request: CreateSubscriptionRequest) {
        client.post("/subscriptions/create") {
            setBody(request)
        }
    }

    override suspend fun import(request: ImportSubscriptionRequest) {
        client.post("/subscriptions/import") {
            setBody(request)
        }
    }

    override suspend fun cancel(id: String) {
        client.get("/subscriptions/$id/cancel")
    }

    override suspend fun delete(id: String) {
        client.delete("/subscriptions/$id")
    }
}